import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ContentSlide } from './contentSlide/contentSlide'
import { DemoButton } from './demoLink'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TryDemoProps {}

const DemoContainer = styled.div`
  text-align: right;
`
const ImageContainer = styled.div`
  text-align: center;
  width: 100%;
`
export const HowCanTengableHelp: React.FunctionComponent<TryDemoProps> = () => {
  return (
    <ContentSlide
      slideLeft
      rightSize={6}
      left={
        <ImageContainer>
          <StaticImage
            placeholder="blurred"
            src="../img/undraw/undraw_to_the_moon_v1mv.svg"
            alt="video call"
            width={926}
            height={749}
          />
        </ImageContainer>
      }
      right={
        <DemoContainer>
          <h2>How Can Tengable Help?</h2>
          <p>
            We bring your team together with our easy to use apps across your
            Windows, Mac or Linux devices. Your Tengable centralized virtual
            workspace makes it feel as if your co-worker is sitting just across
            from you – asking you what you’re having for lunch. Ah, it’s like
            you’ve never left&nbsp;the&nbsp;office!
          </p>
          <DemoButton />
        </DemoContainer>
      }
    />
  )
  return <div />
}

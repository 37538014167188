import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ButtonLink } from './buttonLink'
import { ContentSlide } from './contentSlide/contentSlide'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TryDemoProps {}

const ContentContainer = styled.div`
  text-align: left;
`
const ImageContainer = styled.div`
  text-align: left;
`
export const TheProblem: React.FunctionComponent<TryDemoProps> = () => {
  return (
    <ContentSlide
      slideRight
      rightSize={4}
      left={
        <ContentContainer>
          <h2>
            Do You Have A Virtual Workspace? You May Be Facing The Following
            Challenges.
          </h2>
          <p>
            With the rapid changes 2020 brought, many businesses were required
            to go online, with most teams unprepared for how to effectively
            pivot their communication styles and office culture. There are many
            advantages to virtual teams including higher productivity, lower
            costs and access to global talent, however it’s important to have
            strategies in place to overcome&nbsp;the&nbps;challenges.
          </p>
          <h3>Lack of Communication</h3>
          <p>
            Due to the lack of face-to-face communication, issues can fly under
            the radar and team members may be unsure how to ask for assistance.
            By introducing communication tools such as instant messaging and
            chats, you’ll be able to stay in touch with co-workers more
            efficiently to ensure everyone’s on the&nbsp;same&nbsp;page.
          </p>
          <h3>Reduced Human Interaction</h3>
          <p>
            Human interaction is an important element to develop a strong
            culture. Virtual teams miss out on the social aspect of the office,
            which may lead to less interaction between co-workers and teams,
            potentially creating silos. To encourage socialization, you could
            create an organization-wide channel or chat room
            for&nbsp;general&nbsp;conversation.
          </p>
        </ContentContainer>
      }
      right={
        <ImageContainer>
          <StaticImage
            placeholder="blurred"
            src="../img/undraw/undraw_feeling_blue_4b7q.svg"
            alt="video call"
            width={400}
            height={295}
          />
        </ImageContainer>
      }
    />
  )
  return <div />
}

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ContentSlide } from './contentSlide/contentSlide'
import { DemoButton } from './demoLink'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TryDemoProps {}

const DemoContainer = styled.div`
  text-align: right;
`
const ImageContainer = styled.div`
  text-align: center;
  width: 100%;
`
export const TryDemo: React.FunctionComponent<TryDemoProps> = () => {
  return (
    <ContentSlide
      slideLeft
      rightSize={6}
      left={
        <ImageContainer>
          <StaticImage
            placeholder="blurred"
            src="../img/undraw/undraw_remote_meeting_cbfk.svg"
            alt="video call"
            width={340}
            height={228}
          />
        </ImageContainer>
      }
      right={
        <DemoContainer>
          <h2>Virtual Office. Real&nbsp;Connections.</h2>
          <p>
            The Tengable virtual workspace is a digital, simulated office that
            resembles a video game, with collaboration and communication tools
            to enhance workplace&nbsp;culture.
          </p>
          <DemoButton />
        </DemoContainer>
      }
    />
  )
  return <div />
}

import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import EvStationIcon from '@material-ui/icons/EvStation'
import MoreIcon from '@material-ui/icons/More'
import { ContentSlide } from './contentSlide/contentSlide'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TryDemoProps {}

const ContentContainer = styled.div`
  text-align: left;
`
const ImageContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 0 2.4em;
`
const PadIcon = styled.span`
  transform: translateY(20%);
  display: inline-block;
  margin-right: 4px;
`
export const WhyTengable: React.FunctionComponent<TryDemoProps> = () => {
  return (
    <ContentSlide
      slideLeft
      rightSize={6}
      left={
        <ImageContainer>
          <StaticImage
            placeholder="blurred"
            src="../img/undraw/undraw_welcome_3gvl.svg"
            alt="video call"
            width={868}
            height={731}
          />
        </ImageContainer>
      }
      right={
        <ContentContainer>
          <h2>Why Choose Tengable?</h2>
          <p>
            <PadIcon>
              <SupervisorAccountIcon fontSize="large" />
            </PadIcon>
            Bring people together: forget about formal emails, open the lines of
            communication with your team via chats, audio and visual features.
          </p>
          <p>
            <PadIcon>
              <EmojiTransportationIcon fontSize="large" />
            </PadIcon>
            Improve company culture: maximize human interactions with virtual
            spaces that feel like you’re interacting on a more personal level to
            grow together.
          </p>
          <p>
            <PadIcon>
              <SportsEsportsIcon fontSize="large" />
            </PadIcon>
            Gamified interface for engagement: keep your team engaged with the
            gamified interface ensuring they look forward to their time
            communicating with co-workers.
          </p>
          <p>
            <PadIcon>
              <EvStationIcon fontSize="large" />
            </PadIcon>
            Runs in the background: Tengable is always on in the background, so
            there is no need to worry about your surroundings and co-workers.
          </p>
          <p>
            <PadIcon>
              <MoreIcon fontSize="large" />
            </PadIcon>
            More features to come: we’re currently working on adding more tools
            and integrations to improve your virtual team experience. Stay
            tuned!
          </p>
        </ContentContainer>
      }
    />
  )
  return <div />
}

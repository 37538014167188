import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ContentSlide } from './contentSlide/contentSlide'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TryDemoProps {}

const ContentContainer = styled.div`
  text-align: left;
`
const ImageContainer = styled.div`
  text-align: left;
`
const Li = styled.li`
  font-size: 1.8em;
`
export const Features: React.FunctionComponent<TryDemoProps> = () => {
  return (
    <ContentSlide
      slideRight
      rightSize={4}
      right={
        <ImageContainer>
          <StaticImage
            placeholder="blurred"
            src="../img/undraw/undraw_dev_focus_b9xo.svg"
            alt="video call"
            width={400}
            height={242}
          />
        </ImageContainer>
      }
      left={
        <ContentContainer>
          <h2>Tengable Features</h2>
          <p>
            Tengable is a virtual workspace bringing digital teams together. We
            incorporate collaboration and communication tools to enhance your
            business’ culture and productivity. Virtual workspaces are like
            collaborative hubs for teams that aren’t able to meet face-to-face.
            Although teams can communicate using other internal communication
            software or video conferences, virtual workspaces allow for
            structure and a framework for those interactions. Team members will
            be able to see which of their co-workers are “in the office” which
            encourages open lines of communication with the space to interact,
            and the development&nbsp;of&nbsp;a&nbsp;positive&nbsp;culture.
          </p>
          <ul>
            <Li>Feel connected with live customizable avatars</Li>
            <Li>On Demand video conferencing</Li>
            <Li>Audio communication</Li>
            <Li>Desktop Apps</Li>
            <Li>Virtual assistant bot (coming soon)</Li>
          </ul>
        </ContentContainer>
      }
    />
  )
  return <div />
}

/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import styled from 'styled-components'

import { motion } from 'framer-motion'
import { breakpoints } from '@/theme'
import { StaticImage } from 'gatsby-plugin-image'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import introMp4 from './img/Tengable-intro.mp4'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import introOgv from './img/Tengable-intro.oggtheora.ogv'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import introWebm from './img/Tengable-intro.webmhd.webm'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pinkBG from './img/pink-bg.png'
import { BigPlayButton } from '../playButton/playButton'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const Root = styled(motion.div)`
  position: relative;
  min-height: 750px;
  margin-bottom: -14%;
  z-index: 1;
  @media (min-width: 1410px) {
    margin-bottom: -5%;
  }
  @media (min-width: ${breakpoints.lg}) {
  }
  @media (max-width: ${breakpoints.md}) {
    min-height: 600;
  }
  @media (max-width: ${breakpoints.sm}) {
    min-height: 600px;
  }
  @media (max-width: ${breakpoints.xs}) {
    min-height: 300px;
  }
`
const SwirlBackground = styled(motion.div)`
  background-image: url(${pinkBG});
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
`

const VideoContainer = styled(motion.div)`
  width: 64em;
  max-width: calc(100% - 16px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  & video {
    outline: none;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }
`
const VideoPlayContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  background-color: black;
`

const UndrawImageContainer = styled(motion.div)`
  position: absolute;
  bottom: -80%;
  left: 50%;

  transform: translateX(-50%);
  pointer-events: none;
`
let responsiveTop = 100
try {
  responsiveTop =
    window.innerWidth >= parseInt(breakpoints.sm.replace(`px`, ``), 10)
      ? 100
      : 16
} catch (e) {
  // thats ok
}
const VideoPlayer: React.FunctionComponent = (props) => {
  const [playing, setPlaying] = React.useState(false)
  const [hideVideoCover, setHideVideoCover] = React.useState(false)
  const [videoInteractedWith, setVideoInteractedWith] = React.useState(false)
  const videoPlayer = React.useRef<HTMLVideoElement | null>(null)

  return (
    <VideoContainer
      initial={{ top: 0, opacity: 0 }}
      animate={{ top: responsiveTop, opacity: 1 }}
      transition={{
        duration: 0.9,
        delay: 0.4,
      }}
    >
      <video
        controls
        width="100%"
        height="auto"
        ref={videoPlayer}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onEnded={() => {
          setPlaying(false)
          setHideVideoCover(false)
        }}
      >
        <source src={introWebm} type="video/webm" />
        <source src={introOgv} type="video/ogg" />
        <source src={introMp4} type="video/mp4" />
      </video>
      {!hideVideoCover && (
        <VideoPlayContainer
          initial={{ opacity: videoInteractedWith ? 0 : 1 }}
          transition={{ duration: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => {
            setTimeout(() => {
              videoPlayer.current?.play()
            }, 50)
            setVideoInteractedWith(true)
            setHideVideoCover(true)
          }}
        >
          <StaticImage
            loading="eager"
            placeholder="blurred"
            src="./img/video-background.png"
            width={640}
            height={360}
            alt="Play Tengable Intro Video"
          />
          <BigPlayButton
            onClick={() => {
              setTimeout(() => {
                videoPlayer.current?.play()
              }, 50)
              setVideoInteractedWith(true)
              setHideVideoCover(true)
            }}
          />
        </VideoPlayContainer>
      )}
      <UndrawImageContainer
        transition={{ duration: 1.5, delay: 0.4 }}
        initial={{ opacity: 0, bottom: `-50%` }}
        animate={{ opacity: 1, bottom: playing ? `-60%` : `-45%` }}
      >
        <StaticImage
          placeholder="blurred"
          loading="eager"
          alt="people watching into video"
          src="../../img/undraw/undraw_on_the_office_fbfs.svg"
          width={996}
          height={828}
        // width={540}
        // height={430}
        />
      </UndrawImageContainer>
    </VideoContainer>
  )
}

export const SwirlIntro = () => (
  <Root>
    <SwirlBackground
      initial={{
        height: 0,
        // top: `100%`,
        // opacity: 0.3,
        borderRadius: `0 0 100% 100%`,
      }}
      transition={{
        duration: 1,
      }}
      animate={{
        height: `100%`,
        // top: 0,
        // opacity: 1,
        borderRadius: `0%`,
      }}
    />
    <VideoPlayer />
  </Root>
)

import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Grid, { GridSize } from '@material-ui/core/Grid'

interface ContentSlideProps {
  slideLeft?: boolean
  slideRight?: boolean
  left: JSX.Element | JSX.Element[]
  right: JSX.Element | JSX.Element[]
  rightSize?: GridSize
  leftSize?: GridSize
  leftSizeXS?: GridSize
  rightSizeXS?: GridSize
}

const Root = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AnimateIn = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
const threshold = 0.5
export const ContentSlide: React.FunctionComponent<ContentSlideProps> = (
  props,
) => {
  const { ref: leftRef, inView: leftInView } = useInView({
    threshold,
  })
  const { ref: rightRef, inView: rightInView } = useInView({
    threshold,
  })
  const { slideLeft, slideRight, right, left, rightSizeXS, leftSizeXS } = props
  let { rightSize, leftSize } = props
  if (rightSize === undefined && leftSize === undefined) {
    leftSize = 6
    rightSize = 6
  }
  if (leftSize === undefined && typeof rightSize === `number`) {
    leftSize = (12 - rightSize) as any
  }
  if (rightSize === undefined && typeof leftSize === `number`) {
    rightSize = (12 - leftSize) as any
  }
  const hiddenBase = { opacity: 0 }
  const visibleBase = { opacity: 1 }
  const leftStyle =
    !leftInView && slideLeft
      ? { ...hiddenBase, x: `-10%` }
      : { ...visibleBase, x: `0%` }
  const rightStyle =
    !rightInView && slideRight
      ? { ...hiddenBase, x: `10%` }
      : { ...visibleBase, x: `0%` }

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid ref={leftRef} item xs={leftSizeXS} md={leftSize}>
          <AnimateIn animate={leftStyle}>{left}</AnimateIn>
        </Grid>
        <Grid ref={rightRef} item xs={rightSizeXS} md={rightSize}>
          <AnimateIn animate={rightStyle}>{right}</AnimateIn>
        </Grid>
      </Grid>
    </Root>
  )
}

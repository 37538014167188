import React from 'react'
import styled from 'styled-components'

interface BigPlayButtonProps {
  onClick: () => unknown
}

const ButtonContainer = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const Button = styled.button`
  background: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  overflow: visible;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  appearance: none;

  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
  outline: none;
  & :focus,
  :active {
    background-color: rgba(0, 0, 0, 1);
  }
  & :hover {
    transform: scale(1.2);
  }
`

export const BigPlayButton: React.FunctionComponent<BigPlayButtonProps> = (
  props,
) => {
  const { onClick } = props
  return (
    <ButtonContainer>
      <Button
        aria-label="play-button"
        type="button"
        aria-live="polite"
        onClick={onClick}
      >
        <svg
          width="50%"
          height="50%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 472.615 472.615"
        >
          <path fill="white" d="M50.273 0v472.615l372.069-236.307z" />
        </svg>
      </Button>
    </ButtonContainer>
  )
}

import React from 'react'
import { PageProps } from 'gatsby'

import { PageLayout } from '@/components/pageLayout'
import { SwirlIntro } from '@/components/swirlIntro/swirlIntro'
import Grid from '@material-ui/core/Grid'
import { Container } from '@/components/container'
import { NewsLetter } from '@/components/newsLetter'
import { TryDemo } from '@/components/tryDemo'
import { TheProblem } from '@/components/theProblem'
import styled from 'styled-components'
import { HowCanTengableHelp } from '@/components/howCanTengableHelp'
import { Features } from '@/components/features'
import { WhyTengable } from '@/components/whyTengable'

const SectionWrapper = styled.div`
  padding: 64px 0;
`
const Home: React.FC<PageProps> = ({ path }) => (
  <PageLayout pathname={path}>
    <SwirlIntro />
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NewsLetter />
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <TryDemo />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <TheProblem />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <HowCanTengableHelp />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <Features />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <WhyTengable />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <h2 style={{ textAlign: `center` }}>
              Make Sure to subscribe to the newsletter for updates on new
              features .
            </h2>
            <NewsLetter />
          </SectionWrapper>
        </Grid>
      </Grid>
    </Container>
  </PageLayout>
)

export default Home
